import { Button, ClickAwayListener, Collapse, FormControlLabel, makeStyles, Switch, Tooltip } from '@material-ui/core';
import React from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import FiraSans from '../../../fonts/Fira_Sans/firaSans';
import Colors from '../../../styles/colors';
import Dimensions, { extraNarrowWidth } from '../../../styles/dimensions';
import content from '../../../content/offer/bill.json';
import { OfferData } from '../../../pages/angebot';
import Fjalla from '../../../fonts/Fjalla_One/fjalla';
import CheckoutBreadcrumbs from '../../checkoutBreadcrumbs';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { de } from 'date-fns/locale';
// @ts-ignore
import ActiveIcon from '../../../images/offer/active.svg';
// @ts-ignore
import DisabledIcon from '../../../images/offer/disabled.svg';
// @ts-ignore
import RedErrorIcon from '../../../images/offer/error_red_warning_icon.svg';
// @ts-ignore
import BackArrowIcon from '../../../images/offer/arrow_back_green.svg';

interface Props {
	offer: OfferData;
	step: number;
	changeStep: (value: number) => void;
	goBack: () => void;
	goForward: () => void;
	control: Control<Record<string, any>>;
	watch: (values?: string | string[]) => any | { [key: string]: any };
	trigger: (name?: string | string[]) => Promise<boolean>;
	errors: DeepMap<Record<string, any>, FieldError>;
	setValue: (
		name: string,
		value: any,
		config?: Partial<{
			shouldValidate: boolean;
			shouldDirty: boolean;
		}>
	) => void;
}

interface ClassProps {
	step: number;
	offer: OfferData;
	optionalsAccordionOpen: boolean;
}

const Bill = (props: Props) => {
	const { offer, step, changeStep, goBack, goForward, control, watch, trigger, errors, setValue } = props;

	const [infoOpen, setInfoOpen] = React.useState(false);
	const [contractCalendarOpen, setContractCalendarOpen] = React.useState(false);
	const [trainingCalendarOpen, setTrainingCalendarOpen] = React.useState(false);
	const [optionalsAccordionOpen, setOptionalsAccordionOpen] = React.useState(false);

	const megaValue = watch(content.order.contracts[1].switch?.name);
	const optionals = watch(content.optionals.map((item) => item.name));

	const classes = useClasses({ step, offer, optionalsAccordionOpen });

	React.useEffect(() => {
		const positive = Object.values(optionals).filter((item) => item === true);
		if (megaValue) {
			if (positive.length < 2) {
				setValue(content.order.contracts[1].switch.name, false);
			}
		} else {
			if (positive.length > 1) {
				setValue(content.order.contracts[1].switch.name, true);
			}
		}
	}, [megaValue, optionals]);

	const handleOptionalsAccordionToggle = React.useCallback(() => {
		setOptionalsAccordionOpen((prev) => !prev);
	}, []);

	const handleNext = React.useCallback(() => {
		trigger([content.order.contractStart.name, content.order.trainingStart.name]).then((valid: boolean) => {
			if (valid) {
				goForward();
			}
		});
	}, [step]);

	const toggleInfo = React.useCallback(() => {
		setInfoOpen(!infoOpen);
	}, [infoOpen]);

	const handleTooltipClose = React.useCallback(() => {
		setInfoOpen(false);
	}, []);

	const handleMegaSwitchChange = React.useCallback((onChange, checked) => {
		content.optionals.forEach((item) => {
			setValue(item.name, checked);
		});

		return onChange(checked);
	}, []);

	const totalPrice = React.useMemo(() => {
		const positive = Object.values(optionals).filter((item) => item === true);

		const price =
			content.order.contracts.reduce((acc, item) => acc + item.price, 0) +
			offer.price -
			content.order.contracts[1].price +
			offer.startupFee;

		if (!megaValue && positive.length === 0) {
			return price;
		} else if (!megaValue && positive.length === 1) {
			return price + 4.9;
		} else if (megaValue && offer.group !== '/Einmalig') {
			return price + content.order.contracts[1].price;
		} else {
			return price;
		}
	}, [megaValue, step, optionals]);

	const priceItem = React.useCallback(
		(item, key) => {
			if (key === 0) {
				return (
					new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(offer.price) +
					offer.group
				);
			} else if (key === 2) {
				return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(offer.startupFee);
			} else if (key === 1) {
				let optionalsPrice = item.price;
				const positive = Object.values(optionals).filter((item) => item === true);
				if (!megaValue && positive.length === 1) {
					optionalsPrice = 4.9;
				} else if (!megaValue && positive.length === 0) {
					optionalsPrice = 0;
				}
				return (
					<>
						<Controller
							render={({ value, onChange }) => (
								<Switch
									value={value}
									checked={value}
									classes={{
										root: classes.switchRoot,
										switchBase: classes.switchBase,
										thumb: classes.switchThumb,
										track: classes.switchTrack,
										colorSecondary: classes.switchColorSecondary,
										checked: classes.switchChecked,
									}}
									onChange={(event, checked) => handleMegaSwitchChange(onChange, checked)}
								/>
							)}
							name={item.switch.name}
							control={control}
							defaultValue={true}
						/>

						{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
							offer.group === '/Einmalig' ? 0 : optionalsPrice
						) + offer.group}
					</>
				);
			} else {
				return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item.price);
			}
		},
		[offer, optionals]
	);

	const defaultDate = React.useMemo(() => {
		const date = new Date();
		if (date.getDate() !== 1) {
			return new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1, 1));
		} else if (date.getMonth() === 11) {
			return new Date(Date.UTC(date.getUTCFullYear() + 1, 1, 1));
		}
		return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
	}, []);

	const minDate = React.useMemo(() => {
		const date = new Date();
		return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
	}, []);

	const validateContractDate = React.useCallback((value: Date) => {
		const date = new Date();
		date.setUTCHours(0, 0, 0, 0);

		if (value.getDate() !== 1 || value.getTime() < date.getTime()) {
			return content.order.trainingStart.validationError;
		}
		return true;
	}, []);

	const validateTrainingDate = React.useCallback((value: Date) => {
		if (value === null) {
			return true;
		}
		const date = new Date();
		date.setUTCHours(0, 0, 0, 0);
		if (value.getTime() >= date.getTime()) {
			return true;
		}
		return content.order.trainingStart.validationError;
	}, []);

	return (
		<section className={classes.section}>
			<div className={classes.container}>
				<CheckoutBreadcrumbs step={step} changeStep={changeStep} />

				<h1 className={classes.header}>{content.title}</h1>

				<form onSubmit={(event) => event.preventDefault()}>
					<h4 className={classes.orderHeader}>{content.order.title}</h4>

					{content.order.contracts.map((item, key) => (
						<div key={key} className={classes.orderItem}>
							<div className={classes.orderItemDescriptionContainer}>
								<span className={classes.orderItemHeader}>
									{key === 0 ? offer.name : item.title}
									{key === 1 && (
										<ClickAwayListener onClickAway={handleTooltipClose}>
											<span>
												<Tooltip
													title={content.tooltip}
													arrow
													disableFocusListener
													disableHoverListener
													open={infoOpen}
													placement="bottom-start"
													classes={{
														tooltip: classes.tooltip,
														arrow: classes.tooltipArrow,
													}}>
													{infoOpen ? (
														<img
															src={ActiveIcon}
															alt=""
															onClick={toggleInfo}
															className={classes.infoIcon}
															loading="lazy"
														/>
													) : (
														<img
															src={DisabledIcon}
															alt=""
															onClick={toggleInfo}
															className={classes.infoIcon}
															loading="lazy"
														/>
													)}
												</Tooltip>
											</span>
										</ClickAwayListener>
									)}
									{key === 2 &&
										typeof offer.startupFee === 'number' &&
										`Start Up ${offer.startupFee.toFixed(2).toString().replace('.', ',')}`}
								</span>
								<span className={classes.orderItemDescription}>
									{offer.group === '/Einmalig'
										? item.descriptionOneTime || item.description
										: item.description}
								</span>

								{key === 1 && offer.group !== '/Einmalig' && (
									<div className={classes.optionalsRoot}>
										<Collapse in={optionalsAccordionOpen}>
											<div>
												{content.optionals.map((item, key) => (
													<div key={key} className={classes.optionalsSwitchContainer}>
														<Controller
															render={({ value, onChange }) => (
																<FormControlLabel
																	control={
																		<Switch
																			checked={value}
																			value={value}
																			classes={{
																				root: classes.switchRoot,
																				switchBase: classes.switchBase,
																				thumb: classes.switchThumb,
																				track: classes.switchTrack,
																				colorSecondary:
																					classes.switchColorSecondary,
																				checked: classes.switchChecked,
																			}}
																			onChange={(event, checked) =>
																				onChange(checked)
																			}
																		/>
																	}
																	label={item.label}
																	classes={{
																		labelPlacementStart:
																			classes.optionalsSwitchLabel,
																	}}
																	labelPlacement="start"
																/>
															)}
															name={item.name}
															control={control}
															defaultValue={true}
														/>
														<span className={classes.optionalPrice}>{item.price}</span>
													</div>
												))}
											</div>
										</Collapse>
										<Button
											variant="text"
											className={classes.optionsButton}
											disableRipple
											onClick={handleOptionalsAccordionToggle}>
											{optionalsAccordionOpen ? content.saveOptionals : content.changeOptionals}
										</Button>
									</div>
								)}
							</div>
							<div className={classes.itemPriceContainer}>
								<span className={classes.orderItemPrice}>{priceItem(item, key)}</span>
								{key === 0 && offer.priceSubtext && (
									<span className={classes.orderItemDescription}>{offer.priceSubtext}</span>
								)}
							</div>
						</div>
					))}

					<hr className={classes.divider} />

					<div className={classes.dateContainer}>
						<div className={classes.dateInnerContainer}>
							<MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
								<Controller
									render={({ value, onChange }) => (
										<KeyboardDatePicker
											disableToolbar
											variant="inline"
											format="dd.MM.yyyy"
											label={content.order.contractStart.placeholder}
											value={value}
											onChange={(newDate) => {
												onChange(newDate);
												setContractCalendarOpen(false);
											}}
											minDate={minDate}
											shouldDisableDate={(date: Date) => date.getDate() !== 1}
											className={classes.datePickerRoot}
											autoOk
											KeyboardButtonProps={{
												onFocus: (e) => {
													setContractCalendarOpen(true);
												},
											}}
											PopoverProps={{
												classes: { paper: classes.datePickerPopover },
												disableRestoreFocus: true,
												onClose: () => {
													setContractCalendarOpen(false);
												},
											}}
											InputProps={{
												onFocus: () => {
													setContractCalendarOpen(true);
												},
											}}
											open={contractCalendarOpen}
										/>
									)}
									name={content.order.contractStart.name}
									control={control}
									defaultValue={defaultDate}
									rules={{
										required: content.order.contractStart.fieldError,
										validate: validateContractDate,
									}}
								/>
							</MuiPickersUtilsProvider>
							<div
								className={classes.errorContainer}
								style={{ visibility: errors[content.order.contractStart.name] ? 'visible' : 'hidden' }}>
								<img src={RedErrorIcon} alt="" loading="lazy" />
								<span className={classes.errorSpan}>
									{errors[content.order.contractStart.name]
										? errors[content.order.contractStart.name].message !== ''
											? errors[content.order.contractStart.name].message
											: content.order.contractStart.fieldError
										: ''}
								</span>
							</div>
						</div>

						<div className={classes.dateInnerContainer}>
							<MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
								<Controller
									render={({ value, onChange }) => (
										<KeyboardDatePicker
											disableToolbar
											variant="inline"
											format="dd.MM.yyyy"
											label={content.order.trainingStart.placeholder}
											value={value}
											onChange={(newDate) => {
												onChange(newDate);
												setTrainingCalendarOpen(false);
											}}
											minDate={minDate}
											className={classes.datePickerRoot}
											autoOk
											KeyboardButtonProps={{
												onFocus: (e) => {
													setTrainingCalendarOpen(true);
												},
											}}
											PopoverProps={{
												classes: { paper: classes.datePickerPopover },
												disableRestoreFocus: true,
												onClose: () => {
													setTrainingCalendarOpen(false);
												},
											}}
											InputProps={{
												onFocus: () => {
													setTrainingCalendarOpen(true);
												},
											}}
											open={trainingCalendarOpen}
										/>
									)}
									name={content.order.trainingStart.name}
									control={control}
									defaultValue={null}
									rules={{ validate: validateTrainingDate }}
								/>
							</MuiPickersUtilsProvider>
							<div
								className={classes.errorContainer}
								style={{ visibility: errors[content.order.trainingStart.name] ? 'visible' : 'hidden' }}>
								<img src={RedErrorIcon} alt="" loading="lazy" />
								<span className={classes.errorSpan}>
									{errors[content.order.trainingStart.name]
										? errors[content.order.trainingStart.name].message
										: ''}
								</span>
							</div>
						</div>
					</div>

					<div className={classes.orderItem}>
						<div className={classes.orderItemDescriptionContainer}>
							<span className={classes.summaryHeader}>{content.summary.title}</span>
							<span className={classes.orderItemDescription}>{content.summary.description}</span>
						</div>
						<span className={classes.summaryPrice}>
							{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totalPrice)}
						</span>
					</div>
				</form>
				<div className={classes.formNavigationContainer}>
					<div className={classes.backContainer}>
						<img src={BackArrowIcon} alt="" loading="lazy" />
						<span className={classes.backSpan} onClick={goBack}>
							{content.back}
						</span>
					</div>
					<button className={classes.button} onClick={handleNext}>
						{content.continue}
					</button>
				</div>
			</div>
		</section>
	);
};

export default Bill;

const useClasses = makeStyles({
	section: (props: ClassProps) => ({
		display: props.step === 3 ? 'block' : 'none',
		paddingTop: '2.25em',
		paddingBottom: '2.75em',
		backgroundColor: Colors.offerBackgroundBlack,
		minHeight: 'calc(100vh - 5.25em)',
		boxSizing: 'border-box',
		'@media (max-width: 631px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
		},
	}),
	container: {
		maxWidth: extraNarrowWidth,
		margin: '0 auto',
	},
	header: {
		color: Colors.white,
		marginTop: '0.9em',
		marginBottom: '1.3em',
		textAlign: 'center',
		'@media (max-width: 959px)': {
			marginBottom: '0.9em',
		},
	},
	orderHeader: {
		color: Colors.white,
		marginTop: '1.5em',
		marginBottom: '1.3333333333333333em',
	},
	orderItem: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		margin: '1.5em 0',
	},
	orderItemDescriptionContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	orderItemHeader: {
		color: Colors.whiteLightlyDimmed,
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.tinyText.fontSize,
		lineHeight: Dimensions.tinyText.lineHeight,
	},
	orderItemDescription: {
		color: Colors.whiteHalfOpacity,
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.extraTinyText.fontSize,
		lineHeight: Dimensions.extraTinyText.lineHeight,
		letterSpacing: '0.03333333333333333em',
		marginTop: '0.3333333333333333em',
	},
	orderItemPrice: {
		color: Colors.white,
		fontFamily: Fjalla.regular,
		fontSize: Dimensions.tinyText.fontSize,
		lineHeight: Dimensions.tinyText.lineHeight,
	},
	divider: {
		border: 0,
		borderTop: `1px solid ${Colors.dividerLight}`,
		marginTop: '2.25em',
		marginBottom: '2.5em',
	},
	summaryHeader: {
		color: Colors.whiteLightlyDimmed,
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
	},
	summaryPrice: {
		color: Colors.white,
		fontFamily: Fjalla.regular,
		fontSize: Dimensions.regularText.fontSize,
		lineHeight: Dimensions.regularText.lineHeight,
	},
	formNavigationContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: '3.25em',
		'@media (max-width: 599px)': {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
	},
	backContainer: {
		cursor: 'pointer',
	},
	backSpan: {
		color: Colors.limeGreen,
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		marginLeft: '0.25em',
	},
	button: {
		backgroundColor: Colors.limeGreen,
		color: Colors.white,
		fontFamily: FiraSans.medium,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		border: 0,
		borderRadius: '8px',
		outline: 0,
		cursor: 'pointer',
		padding: '0.875em 2.25em',
		'@media (max-width: 599px)': {
			display: 'block',
			width: '100%',
			marginTop: '2em',
		},
	},
	infoIcon: {
		verticalAlign: 'middle',
		marginLeft: '0.5em',
	},
	tooltip: {
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.extraTinyText.fontSize,
		lineHeight: Dimensions.extraTinyText.lineHeight,
		letterSpacing: '0.03333333333333333em',
		fontWeight: 'normal',
		padding: '0.6666666666666666em',
		backgroundColor: Colors.textBlack,
		borderRadius: '4px',
		margin: '0.75em 0',
	},
	tooltipArrow: {
		color: Colors.textBlack,
	},
	switchRoot: (props: ClassProps) => ({
		display: props.offer.group === '/Einmalig' || props.offer.megaIncluded ? 'none' : 'inline-flex',
		width: '28px',
		height: '15px',
		padding: 0,
		marginRight: '1em',
		overflow: 'visible',
		transform: 'translateY(-2px)',
		'& span input': {
			height: '18px',
		},
	}),
	switchBase: {
		padding: 2,
		color: Colors.textGray,
	},
	switchThumb: {
		width: '8px',
		height: '8px',
		boxShadow: 'none',
		transform: 'translate(3px, 3.25px)',
	},
	switchTrack: {
		border: `2px solid ${Colors.borderBlack}`,
		borderRadius: '12px',
		opacity: 1 + ' !important',
		backgroundColor: Colors.switchGray,
	},
	switchColorSecondary: {
		color: Colors.borderBlack + ' !important',
	},
	switchChecked: {
		transform: 'translateX(10px) !important',
		'&+$switchTrack': {
			backgroundColor: Colors.switchLimeGreen + ' !important',
			borderColor: Colors.borderBlack,
		},
	},
	datePickerRoot: {
		'& label.MuiFormLabel-root': {
			fontFamily: FiraSans.regular,
			fontSize: Dimensions.smallText.fontSize,
			lineHeight: Dimensions.smallText.lineHeight,
			color: Colors.whiteLightlyDimmed,
			opacity: 0.7,
			top: '-2px',
		},
		'& label.MuiFormLabel-root.MuiInputLabel-shrink': {
			opacity: 0.9,
		},
		'& input.MuiInputBase-input': {
			fontFamily: FiraSans.regular,
			fontSize: Dimensions.smallText.fontSize,
			lineHeight: Dimensions.smallText.lineHeight,
			color: Colors.whiteLightlyDimmed,
			paddingRight: '1.5em',
		},
		'& svg': {
			fill: Colors.whiteHalfOpacity,
		},
		'& .MuiInput-root:hover::before': {
			borderColor: Colors.whiteLightlyDimmed,
		},
		'& .MuiInput-underline': {
			'&:after, &:before': {
				borderBottomColor: Colors.dividerGray,
			},
		},
		'& p.MuiFormHelperText-root': {
			display: 'none',
		},
	},
	datePickerPopover: {
		backgroundColor: Colors.paymentContactGray,

		'& button.MuiPickersDay-day': {
			color: Colors.white,
		},
		'& button.MuiPickersDay-daySelected': {
			backgroundColor: Colors.limeGreen,
			color: Colors.textBlack,
		},
		'& button.MuiPickersDay-dayDisabled': {
			color: Colors.whiteHalfOpacity,
		},
		'& .MuiPickersCalendarHeader-transitionContainer p': {
			color: Colors.white,
		},
		'& .MuiPickersCalendarHeader-dayLabel': {
			color: Colors.whiteDimmed,
		},
		'& .MuiPickersCalendarHeader-iconButton': {
			backgroundColor: Colors.calendarButtonGray,
		},
		'& .MuiPickersCalendarHeader-iconButton svg': {
			fill: Colors.white,
		},
	},
	dateContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: '2.25em',
		'@media (max-width: 599px)': {
			flexDirection: 'column',
		},
		'&>div': {
			'@media (max-width: 599px)': {
				marginBottom: '1.25em',
			},
		},
	},
	dateInnerContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	errorContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.extraTinyText.fontSize,
		lineHeight: Dimensions.extraTinyText.lineHeight,
		color: Colors.errorRed,
		position: 'relative',
		top: '1.5625em',
		marginTop: '-1.0625em',
	},
	errorSpan: {
		marginLeft: '0.6666666666666666em',
	},
	optionsButton: {
		...Dimensions.tinyText,
		color: Colors.whiteLightlyDimmed,
		fontFamily: FiraSans.semiBold,
		textTransform: 'none',
		padding: 0,
		textDecoration: 'underline',
		marginTop: '0.857142857em',
		textDecorationThickness: '2px',
		textUnderlineOffset: '1px',
	},
	optionalsRoot: {
		minWidth: 'calc(150% + 28px + 14px)',
	},
	optionalsSwitchContainer: {
		margin: '1em 0',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	optionalsSwitchLabel: {
		color: Colors.whiteLightlyDimmed,
		fontFamily: FiraSans.regular,
		...Dimensions.tinyText,
		marginLeft: 0,
		marginRight: 0,
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'center',
		'& .MuiFormControlLabel-label': {
			marginRight: 'auto',
			width: '240px',
		},
	},
	optionalPrice: {
		color: Colors.white,
		...Dimensions.tinyText,
		fontFamily: Fjalla.regular,
		marginLeft: '0.28571428571428571428571428571429em',
	},
	itemPriceContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
	},
});
