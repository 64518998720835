import axios from 'axios';
import ContactData from '../types/contactData';
import OrderData from '../types/orderData';
import PreSubmitData from '../types/preSubmitData';
import Client from './client';

export interface Service {
    contact(data: ContactData, location: string): Promise<string | boolean>;
    orderSubmitted(data: OrderData): Promise<string | boolean>;
    preSubmit(data: PreSubmitData): Promise<string | boolean>;
}


const service = new Client(axios);

export default service;
