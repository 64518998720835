import { AxiosInstance } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import ContactData from '../types/contactData';
import OrderData from '../types/orderData';
import PreSubmitData from '../types/preSubmitData';
import { Service } from './service';

class Client implements Service {
	axios: AxiosInstance;

	constructor(axios: AxiosInstance) {
		this.axios = axios;
	}

	public async contact(data: ContactData, location: string): Promise<string | boolean> {
		const response = await this.axios.request({
			url: process.env.GATSBY_API_URL,
			method: 'POST',
			data: {
				jsonrpc: '2.0',
				id: uuidv4(),
				method: location.startsWith('/kontakt') ? 'ContactContract' : 'ContactAppointment',
				params: { message: data.toJSON() },
			},
		});
		if (response.data?.error) {
			return response.data.error.message;
		}
		return true;
	}
	public async orderSubmitted(data: OrderData) {
		const response = await this.axios.request({
			url: process.env.GATSBY_API_URL,
			method: 'POST',
			data: {
				jsonrpc: '2.0',
				id: uuidv4(),
				method: 'OrderSubmitted',
				params: { message: data.toJSON() },
			},
		});
		if (response.data?.error) {
			return response.data.error.message;
		}
		return true;
	}
	public async preSubmit(data: PreSubmitData): Promise<string | boolean> {
		const response = await this.axios.request({
			url: process.env.GATSBY_API_URL,
			method: 'POST',
			data: {
				jsonrpc: '2.0',
				id: uuidv4(),
				method: 'SubmitFirstStep',
				params: { message: data.toJSON() },
			},
		});
		if (response.data?.error) {
			return response.data.error.message;
		}
		return true;
	}
}

export default Client;
