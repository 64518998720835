import Cookies from 'js-cookie';

function gtagReport(url) {
	if (typeof window !== 'undefined' && Cookies.get('gatsby-gdpr-google-tagmanager') === 'true') {
		window.gtag('event', 'conversion', {
			page_location: url,
			send_to: `${process.env.GATSBY_GOOGLE_TAG_MANAGER_ID}/t3U0CJ3t7PMCEPTkvZAB`,
		});

		return false;
	}
}

export default gtagReport;
