import { Collapse, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import Dimensions, { defaultWidth } from '../../../styles/dimensions';
import content from '../../../content/offer/contracts.json';
import Colors from '../../../styles/colors';
import FiraSans from '../../../fonts/Fira_Sans/firaSans';
import Fjalla from '../../../fonts/Fjalla_One/fjalla';
import { OfferData } from '../../../pages/angebot';
import { GoogleReview } from '../../../pages/index';
import { Carousel } from 'react-responsive-carousel';
import ArrowLeft from '../../../images/index/services/arrowLeft';
import ArrowRight from '../../../images/index/services/arrowRight';
import { StaticImage } from 'gatsby-plugin-image';
// @ts-ignore
import ActiveIcon from '../../../images/offer/active.svg';
// @ts-ignore
import DisabledIcon from '../../../images/offer/disabled.svg';

interface Props {
	step: number;
	handleOfferChange: (value: OfferData) => void;
	googleReviews: GoogleReview[];
}

interface ClassProps {
	step: number;
	infoOpen: boolean;
}

const Contracts = (props: Props) => {
	const { step, handleOfferChange, googleReviews } = props;

	const [infoOpen, setInfoOpen] = React.useState(false);

	const classes = useClasses({ step, infoOpen });

	const handleInfoClick = React.useCallback(() => {
		setInfoOpen(!infoOpen);
	}, [infoOpen]);

	const offerImages = React.useMemo(() => {
		return [
			<StaticImage
				src="../../../images/offer/mobileEasy12.jpg"
				objectPosition="35% 40%"
				alt=""
				className={classes.image}
				layout="constrained"
				loading="lazy"
				placeholder="blurred"
			/>,
			<StaticImage
				src="../../../images/offer/EasyFlex.jpg"
				alt=""
				className={classes.image}
				layout="constrained"
				loading="lazy"
				placeholder="blurred"
			/>,
			<StaticImage
				src="../../../images/offer/OneTimePayment.jpg"
				alt=""
				className={classes.image}
				layout="constrained"
				loading="lazy"
				placeholder="blurred"
			/>,
		];
	}, []);

	return (
		<section className={classes.section}>
			<div className={classes.container}>
				<h1 className={classes.header}>{content.title}</h1>

				<Grid container spacing={4} className={classes.offerGrid}>
					{content.offers.map((offer, key) => (
						<Grid key={key} item xs={12} sm={6} md={4} lg={3}>
							{offer?.discount && (
								<div className={classes.discountTriangle}>
									<span className={classes.discount}>{offer?.discount}</span>
								</div>
							)}
							{offer.group === '/Einmalig' && (
								<div className={classes.paypalTriangle}>
									<StaticImage
										src="../../../images/offer/pp258.png"
										alt="Beinhaltet Paypal Checkout"
										className={classes.paypalIcon}
										layout="constrained"
										loading="lazy"
										placeholder="blurred"
									/>
								</div>
							)}
							<div className={classes.triangle}>
								<span className={classes.months}>{offer.months}</span>
							</div>
							{offerImages[key]}

							<h4 className={classes.offerHeader}>{offer.name}</h4>
							<p className={classes.offerDescription}>{offer.description}</p>

							<hr className={classes.divider} />

							<p className={classes.price}>
								{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
									offer.price
								)}
								{offer.infoStar && <span className={classes.priceStar}>*</span>}
							</p>

							<button
								className={classes.button}
								onClick={() =>
									handleOfferChange({
										name: offer.name,
										price: offer.price,
										priceSubtext: offer?.priceSubtext,
										startupFee: offer.startupFee,
										group: offer.group,
										megaIncluded: offer.megaIncluded,
									})
								}>
								{content.choose}
							</button>
						</Grid>
					))}
					<Grid item xs={12} sm={6} md={8} lg={3} className={classes.carouselGridItem}>
						<Carousel
							className={classes.quote}
							showThumbs={false}
							showStatus={false}
							infiniteLoop={true}
							swipeable={true}
							emulateTouch={true}
							showIndicators={false}
							dynamicHeight={true}
							renderArrowPrev={(onClickHandler, hasPrev) =>
								hasPrev && (
									<div onClick={onClickHandler} className={classes.arrowPrevious}>
										<ArrowLeft />
									</div>
								)
							}
							renderArrowNext={(onClickHandler, hasNext) =>
								hasNext && (
									<div onClick={onClickHandler} className={classes.arrowNext}>
										<ArrowRight />
									</div>
								)
							}>
							{googleReviews.map((review, key) => (
								<div key={key} className={classes.feedbackContainer}>
									<p className={classes.feedbackText}>{review.body}</p>

									{/* <p className={classes.rating}>Bewertung: {review.rating} / 5</p> */}

									<div className={classes.badge}>
										<img
											src={review.sourceImage}
											alt=""
											loading="lazy"
											className={classes.avatar}
										/>
										<div className={classes.info}>
											<span className={classes.name}>{review.source}</span>
											<span className={classes.accreditation}>{review.date}</span>
										</div>
									</div>
								</div>
							))}
						</Carousel>
					</Grid>
				</Grid>

				<Grid container spacing={4}>
					<Grid item md={7}>
						<div onClick={handleInfoClick} className={classes.infoContainer}>
							{infoOpen ? (
								<img src={ActiveIcon} alt="" loading="lazy" />
							) : (
								<img src={DisabledIcon} alt="" loading="lazy" />
							)}
							<span className={classes.infoBadgeSpan}>{content.info.infoBadge.text}</span>
						</div>
					</Grid>
					<Grid item md={5} className={classes.infoColumn}>
						<Collapse in={infoOpen} timeout={500}>
							<p className={classes.infoParagraph}>{content.info.easy12}</p>
							<p className={classes.infoParagraph}>{content.info.easyFlex}</p>
							<p className={classes.infoParagraph}>{content.info.oneTimePayment}</p>
						</Collapse>
						<p className={classes.infoParagraphVat}>{content.info.vat}</p>
					</Grid>
				</Grid>
			</div>
		</section>
	);
};

export default Contracts;

const useClasses = makeStyles({
	section: (props: ClassProps) => ({
		display: props.step === 0 ? 'block' : 'none',
		paddingTop: '6.25em',
		paddingBottom: '2.75em',
		backgroundColor: Colors.offerBackgroundBlack,
		minHeight: 'calc(100vh - 5.25em)',
		boxSizing: 'border-box',
		'@media (max-width: 1271px)': {
			paddingLeft: '2.25em',
			paddingRight: '2.25em',
		},
		'@media (max-width: 959px)': {
			paddingTop: '4.25em',
		},
		'@media (max-width: 599px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
			paddingTop: '2.25em',
		},
	}),
	container: {
		maxWidth: defaultWidth,
		margin: '0 auto',
	},
	offerGrid: {
		marginBottom: '3em',
	},
	header: {
		color: Colors.white,
		marginTop: 0,
		marginBottom: '1.5em',
		'@media (max-width: 599px)': {
			fontSize: Dimensions.largeText.fontSize,
			lineHeight: Dimensions.largeText.lineHeight,
		},
	},
	offerHeader: {
		color: Colors.white,
		marginTop: '0.6666666666666666em',
		marginBottom: '0.16666666666666666em',
	},
	offerDescription: {
		margin: 0,
		color: Colors.whiteLightlyDimmed,
		fontFamily: FiraSans.book,
		fontSize: Dimensions.regularText.fontSize,
		lineHeight: Dimensions.regularText.lineHeight,
	},
	divider: {
		border: 0,
		borderTop: `1px solid ${Colors.white}`,
		marginTop: '0.875em',
		marginBottom: '1.75em',
	},
	price: {
		fontFamily: Fjalla.regular,
		fontSize: Dimensions.smallTitle.fontSize,
		lineHeight: Dimensions.smallTitle.lineHeight,
		color: Colors.white,
	},
	priceStar: (props: ClassProps) => ({
		opacity: props.infoOpen ? 1 : 0,
		transition: 'opacity 0.5s ease-in',
		marginLeft: '0.125em',
		color: Colors.limeGreen,
	}),
	button: {
		backgroundColor: Colors.limeGreen,
		color: Colors.white,
		fontFamily: FiraSans.semiBold,
		fontSize: Dimensions.tinyText.fontSize,
		lineHeight: Dimensions.tinyText.lineHeight,
		border: 0,
		borderRadius: '4px',
		outline: 0,
		cursor: 'pointer',
		width: '100%',
		padding: '0.7142857142857143em 0',
	},
	infoContainer: {
		display: 'inline-flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'start',
		cursor: 'pointer',
	},
	infoBadgeSpan: {
		marginLeft: '0.5714285714285714em',
		fontFamily: FiraSans.book,
		fontSize: Dimensions.tinyText.fontSize,
		lineHeight: Dimensions.tinyText.lineHeight,
		color: Colors.whiteLightlyDimmed,
	},
	infoParagraph: {
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.extraTinyText.fontSize,
		lineHeight: Dimensions.extraTinyText.lineHeight,
		letterSpacing: '0.4px',
		color: Colors.whiteLightlyDimmed,
		marginTop: 0,
		marginBottom: '1.1428571428571428em',
		whiteSpace: 'pre-wrap',
	},
	infoParagraphVat: {
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.tinyText.fontSize,
		lineHeight: Dimensions.tinyText.lineHeight,
		color: Colors.whiteLightlyDimmed,
		margin: 0,
	},
	infoColumn: {
		'& $infoParagraph:last-child': {
			marginBottom: '1.8333333333333333em',
		},
	},
	image: {
		marginTop: '-85px',
		maxWidth: '100%',
		'@media (min-width: 300px)': {
			height: '425px',
		},
		'@media (min-width: 400px)': {
			height: '500px',
		},
		'@media (min-width: 500px)': {
			height: '650px',
		},
		'@media (min-width: 600px)': {
			height: '350px',
		},
		'@media (min-width: 700px)': {
			height: '400px',
		},
		'@media (min-width: 800px)': {
			height: '500px',
		},
		'@media (min-width: 900px)': {
			height: '550px',
		},
		'@media (min-width: 960px)': {
			height: '400px',
		},
		'@media (min-width: 1100px)': {
			height: '450px',
		},
		'@media (min-width: 1200px)': {
			height: '500px',
		},
		'@media (min-width: 1280px)': {
			height: '400px',
		},
	},
	discountTriangle: {
		width: 0,
		height: 0,
		borderRight: '85px solid transparent',
		borderTop: `85px solid ${Colors.discountRed}`,
		position: 'absolute',
		zIndex: 1,
	},
	paypalTriangle: {
		width: 0,
		height: 0,
		borderRight: '85px solid transparent',
		borderTop: `85px solid ${Colors.white}`,
		position: 'absolute',
		zIndex: 1,
	},
	discount: {
		color: Colors.white,
		fontFamily: Fjalla.regular,
		fontSize: Dimensions.largeText.fontSize,
		lineHeight: Dimensions.largeText.lineHeight,
		position: 'absolute',
		left: '25px',
		top: '-90px',
		transform: 'translate(-50%, 50%) rotate(-45deg)',
		whiteSpace: 'nowrap',
	},
	paypalIcon: {
		width: '35px',
		height: '35px',
		position: 'relative',
		top: '-75px',
		left: '7.5px',
	},
	triangle: {
		width: 0,
		height: 0,
		borderLeft: '85px solid transparent',
		borderTop: `85px solid ${Colors.darkLimeGreen}`,
		position: 'relative',
		left: 'calc(100% - 85px)',
		zIndex: 1,
	},
	months: {
		color: Colors.white,
		fontFamily: Fjalla.regular,
		fontSize: Dimensions.largeText.fontSize,
		lineHeight: Dimensions.largeText.lineHeight,
		position: 'absolute',
		left: '-20px',
		top: '-94px',
		transform: 'translate(-50%, 50%)',
	},

	quote: {
		marginBottom: '1em',
		'& li:not(.dot)': {
			backgroundColor: 'transparent',
		},
		'& li.dot': {
			backgroundColor: Colors.textGray + ' !important',
		},
		'& li.dot:not(.selected)': {
			opacity: 0.5,
		},
	},
	feedbackContainer: {
		paddingBottom: '4em',
	},
	feedbackText: {
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		color: Colors.whiteDimmed,
		whiteSpace: 'pre-wrap',
		textAlign: 'left',
	},
	// rating: {
	// 	textAlign: 'left',
	// 	fontFamily: FiraSans.semiBold,
	// 	fontSize: Dimensions.smallText.fontSize,
	// 	lineHeight: Dimensions.smallText.lineHeight,
	// 	color: Colors.whiteDimmed,
	// },
	badge: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginTop: '1.5em',
		textAlign: 'left',
		paddingBottom: '1.5em',
	},
	avatar: {
		width: 'initial !important',
		verticalAlign: 'middle !important',
	},
	info: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: '0.75em',
	},
	name: {
		fontFamily: FiraSans.semiBold,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		color: Colors.white,
	},
	accreditation: {
		fontFamily: FiraSans.light,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		color: Colors.whiteDimmed,
	},
	arrowPrevious: {
		position: 'absolute',
		zIndex: 2,
		top: 'calc(100% - 15px)',
		width: 30,
		height: 30,
		cursor: 'pointer',
		left: 0,
		transform: 'translateY(-25px) scale(0.5)',
		'& svg g': {
			stroke: Colors.white,
		},
	},
	arrowNext: {
		position: 'absolute',
		zIndex: 2,
		top: 'calc(100% - 15px)',
		width: 30,
		height: 30,
		cursor: 'pointer',
		right: 0,
		transform: 'translateY(-25px) scale(0.5)',
		'& svg g': {
			stroke: Colors.white,
		},
	},
	carouselGridItem: {
		'@media (min-width: 960px) and (max-width: 1279px)': {
			margin: '0 auto',
			marginTop: '4em',
		},
		'@media (max-width: 599px)': {
			marginTop: '2em',
		},
	},
});
